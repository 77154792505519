import { combineReducers, configureStore } from "@reduxjs/toolkit";

// ** Reducers Imports
import Crops from "../pages/Home/store";
import CropsStore from "../pages/Home/CropSection/store";
import CropDetails from "../pages/CropDetails/store";
import CropSolution from "../pages/CropDetailsSolution/store";
import Subscription from "../pages/Subscription/store";
import CheckUser from "../pages/CheckUser/store";
import auth from "../redux/auth";
import Videos from "../pages/Videos/store";
import OTP from "../pages/OTP/store";
import Sidebar from "../components/Sidebar/store";
import MandiRates from "../pages/MandiRates/store";
import MarketPlace from "../pages/MarketPlace/store";

const rootReducer = combineReducers({
  Crops,
  CropsStore,
  auth,
  CropDetails,
  CropSolution,
  Subscription,
  CheckUser,
  Videos,
  OTP,
  Sidebar,
  MandiRates,
  MarketPlace,
});

export default rootReducer;
