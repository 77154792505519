import React from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Divider, Drawer } from "@mantine/core";

const Sidebar2 = ({ isOpen, closeSidebar }) => {
  const authStore = useSelector((state) => state.auth)
  const navigate = useNavigate();
  const handleNavigation = (path) => navigate(path);

  const bugerMenu = [
    {
      icon: "/hamburger/user-green.svg",
      title: "ذاتی معلومات",
      className: "w-[16px]",
      onClick: () => {
        handleNavigation("/personal-info");
        // dispatch(open())
      },
      containerClass: `cursor-pointer ${authStore.authData?.msisdn === process.env.REACT_APP_DUMMY_NUMBER && "hidden"}`
    },
    {
      icon: "/hamburger/leaf-green.svg",
      title: "آپ کی فصلیں",
      className: "w-[16px]",
      onClick: () => handleNavigation("/your-crops"),
      containerClass: "cursor-pointer"
    },
    {
      icon: "/hamburger/shopping-bag.svg",
      title: "میرے آرڈرز",
      className: "w-[16px]",
      onClick: () => handleNavigation("/market/my-orders"),
      containerClass: "cursor-pointer"
    },
    // {
    //   icon: "/hamburger/cart.png",
    //   title: "میرے آڈر",
    //   className: "p-[2px]",
    //   onClick: () => { },
    //   containerClass: "opacity-50 cursor-not-allowed"
    // },
    // {
    //   icon: "/hamburger/payment.png",
    //   title: "ادائیگی کا طریقہ",
    //   className: "p-[2px]",
    //   onClick: () => { },
    //   containerClass: "opacity-50 cursor-not-allowed"
    // },
    // {
    //   icon: "/hamburger/bell-plus.png",
    //   title: "اطلاعات",
    //   className: "p-[2px]",
    //   onClick: () => { },
    //   containerClass: "opacity-50 cursor-not-allowed"
    // },
    // {
    //   icon: "/hamburger/question-mark.png",
    //   title: "مدد اور تعاون کا مرکز",
    //   className: "p-[2px]",
    //   onClick: () => { },
    //   containerClass: "opacity-50 cursor-not-allowed"
    // },
    // {
    //   icon: "/hamburger/bell-green.svg",
    //   title: "سبسکرائب کریں",
    //   className: "w-[16px]",
    //   onClick: () => handleNavigation("/subscription"),
    //   containerClass: "cursor-pointer"
    // },
    // {
    //   icon: "/hamburger/logout-green.svg",
    //   title: "لاگ آوٹ",
    //   className: "w-[16px]",
    //   onClick: () => {
    //     sessionStorage.removeItem("authToken");
    //     localStorage.removeItem("persist:root");
    //     closeSidebar();
    //     navigate("/");
    //   },
    //   containerClass: "cursor-pointer"
    // },

  ]
  return (
    <Drawer
      opened={isOpen}
      onClose={closeSidebar}
      withCloseButton={false}
      position="right"
      size={"xs"}
    >
      <div
        className="sidebar-container"
      >
        <div className="header-text flex items-center">
          <h1>معلومات</h1>
          <button onClick={closeSidebar}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0001 29.3327C23.3639 29.3327 29.3334 23.3631 29.3334 15.9993C29.3334 8.63555 23.3639 2.66602 16.0001 2.66602C8.63628 2.66602 2.66675 8.63555 2.66675 15.9993C2.66675 23.3631 8.63628 29.3327 16.0001 29.3327Z"
                fill="#D3D3D3"
              />
              <path
                d="M20 12L12 20"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 12L20 20"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        {/* {subsStore?.subscriberDetails?.data?.fullName && <div className="profile-header flex items-center justify-end">
        <div className="title-section p-1 ">
          <h4>{subsStore.subscriberDetails.data.fullName}</h4>
        </div>
      </div>} */}

        {/* <h1 style={{ direction: "rtl" }} className="text-[16px] mt-4 font-bold">معلومات</h1> */}
        <div className="profile-list-section pt-3">
          <ul>
            {
              bugerMenu.map((item, index) => (
                <div key={index}>
                  <li className={item.containerClass}
                    onClick={item.onClick}
                  >
                    <div className="flex justify-between items-center pl-2">
                      <svg
                        className="arrow-svg"
                        width="10"
                        height="14"
                        viewBox="0 0 10 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.59013 13.6673L0.833374 7.00065L7.59013 0.333984L9.16671 1.88954L3.98653 7.00065L9.16671 12.1118L7.59013 13.6673Z"
                          fill="black"
                        />
                      </svg>
                      <div className="flex items-center gap-2">
                        <p className="pt-3 text-[16px] font-bold">{item.title}</p>
                        <div className="w-6 h-6 bg-gray-100 rounded-full p-1 flex justify-center items-center">
                          <img
                            src={item.icon}
                            className={`w-[14px] ${item.className}`}
                            alt="user icon"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <Divider />
                </div>
              ))
            }
          </ul>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar2;
