import { Navigate } from "react-router-dom";
import { lazy } from 'react';
// import Verification from "../pages/Verification/Verification";
// import CheckUser from "../pages/CheckUser/CheckUser";
// import OTP from "../pages/OTP/OTP";
// import Home from "../pages/Home/Home";
// import YourCrops from "../pages/YourCrops";
// import CropDetails from "../pages/CropDetails/CropDetails";
// import CropDetailsSolution from "../pages/CropDetailsSolution/CropDetailsSolution";
// import PersonalInfo from "../pages/PersonalInfo";
// import Subscription from "../pages/Subscription/Subscription";
// import ConfirmSubscription from "../pages/ConfirmSubscription/ConfirmSubscription";
// import Videos from "../pages/Videos/Videos";
// import VideoPlayer from "../pages/VideoPlayer";
// import MandiRates from "../pages/MandiRates";
// import Market from "../pages/MarketPlace";
// import Offers from "../pages/MarketPlace/pages/Offers";
// import TodayOffers from "../pages/MarketPlace/pages/TodayOffers";
// import MarketCategory from "../pages/MarketPlace/pages/Category";
// import MarketBrand from "../pages/MarketPlace/pages/Brand";
// import SuggestedProducts from "../pages/MarketPlace/pages/SuggestedProducts";
// import BestSelling from "../pages/MarketPlace/pages/BestSelling";
// import ProductPage from "../pages/MarketPlace/pages/ProductPage";
// import MyCart from "../pages/MarketPlace/pages/MyCart";
// import Checkout from "../pages/MarketPlace/pages/Checkout";
// import OrderPlaced from "../pages/MarketPlace/pages/OrderPlaced";
// import MyOrders from "../pages/MarketPlace/pages/MyOrders";
// import OrderDetails from "../pages/MarketPlace/pages/OrderDetails";
// import ErrorPage from "../pages/ErrorPage";
const Verification = lazy(() => import("../pages/Verification/Verification"));
const CheckUser = lazy(() => import("../pages/CheckUser/CheckUser"));
const OTP = lazy(() => import("../pages/OTP/OTP"));
const Home = lazy(() => import("../pages/Home/Home"));
const YourCrops = lazy(() => import("../pages/YourCrops"));
const CropDetails = lazy(() => import("../pages/CropDetails/CropDetails"));
const CropDetailsSolution = lazy(() => import("../pages/CropDetailsSolution/CropDetailsSolution"));
const PersonalInfo = lazy(() => import("../pages/PersonalInfo"));
const Subscription = lazy(() => import("../pages/Subscription/Subscription"));
const ConfirmSubscription = lazy(() => import("../pages/ConfirmSubscription/ConfirmSubscription"));
const Videos = lazy(() => import("../pages/Videos/Videos"));
const VideoPlayer = lazy(() => import("../pages/VideoPlayer"));
const MandiRates = lazy(() => import("../pages/MandiRates"));
const Market = lazy(() => import("../pages/MarketPlace"));
const Offers = lazy(() => import("../pages/MarketPlace/pages/Offers"));
const TodayOffers = lazy(() => import("../pages/MarketPlace/pages/TodayOffers"));
const MarketCategory = lazy(() => import("../pages/MarketPlace/pages/Category"));
const MarketBrand = lazy(() => import("../pages/MarketPlace/pages/Brand"));
const SuggestedProducts = lazy(() => import("../pages/MarketPlace/pages/SuggestedProducts"));
const BestSelling = lazy(() => import("../pages/MarketPlace/pages/BestSelling"));
const ProductPage = lazy(() => import("../pages/MarketPlace/pages/ProductPage"));
const MyCart = lazy(() => import("../pages/MarketPlace/pages/MyCart"));
const Checkout = lazy(() => import("../pages/MarketPlace/pages/Checkout"));
const OrderPlaced = lazy(() => import("../pages/MarketPlace/pages/OrderPlaced"));
const MyOrders = lazy(() => import("../pages/MarketPlace/pages/MyOrders"));
const OrderDetails = lazy(() => import("../pages/MarketPlace/pages/OrderDetails"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));

export const publicRoutes = [
    {
        path: "/",
        elem: <Navigate to="/home" />,
    },
    {
        path: "/authentication",
        elem: <Verification />,
    },
    {
        path: "/verification",
        elem: <CheckUser />,
    },
    {
        path: "/verification/otp-process",
        elem: <OTP />,
    },
];

export const privateRoutes = [
    {
      path: "/home",
      elem: <Home />,
    },
    // ================================= | Crop Routes | =================================
    {
      path: "/your-crops",
      elem: <YourCrops />,
    },
    {
      path: "/crop-details/:id",
      elem: <CropDetails />,
    },
    {
      path: "/crop-details/solution/:id",
      elem: <CropDetailsSolution />,
    },
    // ================================= | Profile Route | =================================
    {
      path: "/personal-info",
      elem: <PersonalInfo />,
    },
    // ================================= | Subscription Routes | =================================
    {
      path: "/subscription",
      elem: <Subscription />,
    },
    {
      path: "/confirm-subscription",
      elem: <ConfirmSubscription />,
    },
    // ================================= | Videos Routes | =================================
    {
      path: "/videos",
      elem: <Videos />,
    },
    {
      path: "/video-player",
      elem: <VideoPlayer />,
    },
    // ================================= | Mandi Route | =================================
    {
      path: "/mandi-rates",
      elem: <MandiRates />,
    },
    // ================================= | Market Place Routes | =================================
    {
      path: "/market",
      elem: <Market />,
    },
    {
      path: "/market/offers",
      elem: <Offers />,
    },
    {
      path: "/market/today-offers",
      elem: <TodayOffers />,
    },
    {
      path: "/market/category/:category",
      elem: <MarketCategory />,
    },
    {
      path: "/market/brand/:brand",
      elem: <MarketBrand />,
    },
    {
      path: "/market/suggested-products",
      elem: <SuggestedProducts />,
    },
    {
      path: "/market/best-selling",
      elem: <BestSelling />,
    },
    {
      path: "/market/product/:productName",
      elem: <ProductPage />,
    },
    {
      path: "/market/cart",
      elem: <MyCart />,
    },
    {
      path: "/market/checkout",
      elem: <Checkout />,
    },
    {
      path: "/market/order-placed/:orderId",
      elem: <OrderPlaced />,
    },
    {
      path: "/market/my-orders",
      elem: <MyOrders />,
    },
    {
      path: "/market/order-details/:orderId",
      elem: <OrderDetails />,
    },
    // ================================= | Error Route | =================================
    {
      path: "/error-page",
      elem: <ErrorPage />,
    }
  ];