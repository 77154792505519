import React from 'react'
import { Navigate } from 'react-router-dom';

export class ErrorBoundary extends React.Component {
    componentDidCatch(error, errorInfo) {
        console.log('Error:', error);
        console.log('Error Info:', errorInfo);
        alert(error)
    }

    render() {
        return this.props.children;
    }
}

const Unprotected = ({ children }) => {
    const authToken = sessionStorage.getItem("authToken");
    if (authToken) return <Navigate to="/home" />
    return <ErrorBoundary>
        {children}
    </ErrorBoundary>
}

export default Unprotected