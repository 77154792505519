import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Header from "./components/Header/Header";
import React, { useState, Suspense, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Unprotected, { ErrorBoundary } from "./components/Wrappers/Unprotected";
import { closeDrawer, toggle } from "./components/Sidebar/store";
import ErrorPage from "./pages/ErrorPage";
import { AuthProvider } from "./utils/AuthContext";
import { initGA, trackPageview } from "./configs/analytics";
import Sidebar2 from "./components/Sidebar/Sidebar2";
import Sidebar from "./components/Sidebar/Sidebar";
import { privateRoutes, publicRoutes } from "./utils/routes";
import { useRouteChecks } from "./hooks/useRouteChecks";

export const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
  </div>
);

function App() {

  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const routeChecks = useRouteChecks(pathname);

  const initializeAnalytics = () => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
  };

  // useEffect(() => {
  //   initializeAnalytics();
  //   trackPageview(location.pathname + location.search);
  // }, [location]);

  const isDrawerOpen = useSelector((state) => state.Sidebar.value);

  const [isSidebarOpen, setSidebarOpen] = useState(isDrawerOpen);

  useEffect(() => {
    if (isDrawerOpen !== isSidebarOpen) {
      setSidebarOpen(isDrawerOpen);
    }
  }, [isDrawerOpen]);

  const toggleSidebar = () => {
    dispatch(toggle());
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    dispatch(closeDrawer());
    setSidebarOpen(false);
  };

  const showHeader = !routeChecks.isRootRoute &&
    !routeChecks.isSubscriptionRoute &&
    !routeChecks.isVerificationRoute &&
    !routeChecks.isProfileRoute &&
    !routeChecks.isCropDetailsRoute &&
    !routeChecks.isSubscriptionConfirmationRoute &&
    !routeChecks.isYourCropsRoute &&
    !routeChecks.isVideoPlayerRoute &&
    !routeChecks.isMarketOffersRoute &&
    !routeChecks.isMyOrdersRoute &&
    !routeChecks.isErrorRoute;

  const showNavigation = !routeChecks.isRootRoute &&
    !routeChecks.isSubscriptionRoute &&
    !routeChecks.isVerificationRoute &&
    !routeChecks.isProfileRoute &&
    !routeChecks.isCropDetailsRoute &&
    !routeChecks.isSubscriptionConfirmationRoute &&
    !routeChecks.isYourCropsRoute &&
    !routeChecks.isVideoPlayerRoute &&
    !routeChecks.isProductRoute &&
    !routeChecks.isCartRoute &&
    !routeChecks.isCheckoutRoute &&
    !routeChecks.isOrderPlacedRoute &&
    !routeChecks.isOrderDetailsRoute &&
    !routeChecks.isMyOrdersRoute &&
    !routeChecks.isErrorRoute;

  // Show sidebar drawer on all routes except profile and your crops
  const showSidebar = !routeChecks.isProfileRoute && !routeChecks.isYourCropsRoute && !routeChecks.isMyOrdersRoute;
  return (
    <AuthProvider>
      <div className="App">
        {/* <Header /> */}
        {showHeader && <Header toggleSidebar={toggleSidebar} />}

        <Sidebar2 isOpen={isSidebarOpen && showSidebar} closeSidebar={closeSidebar} />
        {/* <Sidebar isOpen={isSidebarOpen && showSidebar} closeSidebar={closeSidebar} /> */}

        {/* Main content */}
        <div className="content">
          <Routes>
            {publicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <Unprotected>{route.elem}</Unprotected>
                  </Suspense>
                }
              />
            ))}

            {privateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <ErrorBoundary>{route.elem}</ErrorBoundary>
                  </Suspense>
                }
              />
            ))}

            <Route path="*" element={<ErrorPage />} />

          </Routes>
        </div>

        {/* Navigation bar */}
        {showNavigation && <Navigation />}
      </div>
    </AuthProvider>
  );
}

export default memo(App);
